import { Link } from '@reach/router'
import { Body, Col, Divisor, FaIcon, Grid, H3, Row } from 'atomic'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { Placeholder } from 'atomic/legacy/mol.placeholder/placeholder.component'
import { LoadingState } from 'atomic/legacy/obj.loading-state'
import { getListJsonLd } from '@root/src/components/legacy/mol.seo/build-list-json-ld'
import { normalizeUrl } from 'utils/url'
import * as React from 'react'
import { ListNoBullets } from '../atm.list-no-bullets/ListNoBullets.component'
import SvgBuscaSemResultado from 'atomic/atm.svg-icon/ic-busca-sem-resultado'
import { SEO } from '../legacy/mol.seo/seo.component'
import { PaginationContainer } from '../legacy/obj.pagination-container/pagination.container'
import { PaginationRow } from '../legacy/pagination-wrapper.component'
import TitleWithBreadcrumbRow from '../org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { MainTitle } from '@root/src/components/Typography'
import { Checkbox, CheckboxContainer, CheckboxSpan, FilterExamsWrapper, FilterExamsText, ExamsDetailsLink, TextListItemStyled, TopExame, TopExamesText, TopExamesWrapper, TotalExamesWrapper } from './text-list-page.style'
import Synonyms from '@root/src/modules/exam/component/Synonyms'

interface TextListPageProps {
  location: Location
  pageData: TextListPageData
  examsClassification: any
  setExamsClassification: any
  handleClickFilter: any
  loading?: boolean
  featuredExams?: {
    title: string
    slug: string
  }[]

  /** header Row: search, filter */
  children: JSX.Element | JSX.Element[]
}

export interface TextListItem {
  title: string
  slug: string
  synonyms: string[]
}

export interface TextListPageData {
  title: string
  searchPlaceholder: string
  selectPlaceholder: string
  getItems: (startIndex: number, endIndex: number) => TextListItem[]
  itemsCount: number
  showItemCount: boolean
  col2Title: string
  col2ContentHtml: string
  seo: {
    canonicalUrl: string
    siteUrl: string
    title: string
    description: string
    imageUrl: string
    imageAlt: string
  }
}

export const TextListHeader: React.FunctionComponent<any> = props => <>{props.children}</>
TextListHeader.displayName = 'TextListHeader'

export const TextListFooter: React.FunctionComponent<any> = props => <>{props.children}</>
TextListFooter.displayName = 'TextListFooter'

export const TextListPage: React.FunctionComponent<TextListPageProps> = props => {
  const pageData = props.pageData
  const seo = pageData.seo
  const featuredExams = props.featuredExams
  const theme = useTheme()
  const downOfMd = useMediaQuery(theme.breakpoints.down('sm'))

  const adjustMarginTop = downOfMd ? 
    process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' ? 100 : 0 : 0

  const header = React.Children.map(props.children, (child: any) => {
    if (child && child.type.displayName === TextListHeader.displayName) {
      return child
    }
    return ''
  })
  const footer = React.Children.map(props.children, (child: any) => {
    if (child && child.type.displayName === TextListFooter.displayName) {
      return child
    }
    return ''
  })

  return (
    <Grid style={{ marginTop: adjustMarginTop }} maxWidth="md">
      <TitleWithBreadcrumbRow/>
      <Row style={{marginLeft: "2px", marginTop: "-24px", marginBottom: "16px"}} >
      <MainTitle variant='h2'>{pageData.title}</MainTitle>
      </Row>
      {header}
      <Row mb>
        <Col xs={12} md={4}>
          <FilterExamsWrapper>
            <FilterExamsText>Filtrar</FilterExamsText>
            {props.examsClassification?.map((item, index) => (
              <CheckboxContainer
              id={item.classification}
              onClick={() => (item.quantity > 0 ? props.handleClickFilter(index) : null)}
              style={{ cursor: item.quantity > 0 ? 'pointer' : 'default' }}
              key={item.classification}
            >
              <Checkbox
                checked={item.selected}
                disabled={item.quantity === 0}
                type='checkbox'
                name={item.classification}
                style={{
                  cursor: item.quantity > 0 ? 'pointer' : 'default'
                }}
                onChange={() => {}}
              />
              <label style={{ cursor: item.quantity > 0 ? 'pointer' : 'default' }} htmlFor={item.classification}>
                {item.classification}
              </label>
              <CheckboxSpan>({item.quantity})</CheckboxSpan>
            </CheckboxContainer>
            ))}
          </FilterExamsWrapper>
          <TopExamesWrapper>
            <TopExamesText>Mais buscados</TopExamesText>
            {featuredExams?.map((item, index) => (
              <TopExame>
                <Link
                  id={`item-text-list-page-${index}`}
                  to={normalizeUrl(`${props.location.pathname}/${item.slug}`)}
                >
                  <p>{item.title}</p>
                </Link>
                <FaIcon.ChevronRight />
              </TopExame>
            ))}
          </TopExamesWrapper>

        </Col>
        <Col xs={12} md={8}>
          <PaginationContainer>
            {paginationProps => {
              const startIndex =
                (paginationProps.pagination.current - 1) * paginationProps.limit.current
              const endIndex = paginationProps.pagination.current * paginationProps.limit.current
              //const pageList = props?.pageData?.getItems(startIndex, endIndex)
              const pageList = props?.pageData?.getItems(startIndex, endIndex) 
              const itemsCount = props.pageData.itemsCount
              const showPagination = paginationProps.limit.current < itemsCount
                            
              return (
                <>
                  <SEO
                    jsonld={getListJsonLd(
                      pageList.map(item => ({ url: `${props.location.pathname}/${item.slug}` }))
                    )}
                    socialMedia={{
                      canonicalUrl: seo.canonicalUrl,
                      title: seo.title,
                      image: seo.imageUrl,
                      imageAlt: seo.imageAlt,
                      description: seo.description
                    }}
                  />
                  {props.pageData.showItemCount && props.pageData.itemsCount > 0 && (
                    <Row>
                      <TotalExamesWrapper>
                        <Col>
                          <Body>
                            {props.pageData.itemsCount > 1
                              ? `Exibindo ${paginationProps.limit.current > props.pageData.itemsCount ? props.pageData.itemsCount : paginationProps.limit.current} de ${props.pageData.itemsCount} exames`
                              : `Exibindo ${props.pageData.itemsCount} de ${props.pageData.itemsCount} exame`}
                          </Body>
                        </Col>
                      </TotalExamesWrapper>
                    </Row>
                  )}
                  <Row mb>
                    <Col xs={12}>
                      <LoadingState loading={props.loading} data={pageList.length > 0}>
                        <LoadingState.NoData>
                          <Col xs={12}>
                            <Placeholder
                              icon={<SvgBuscaSemResultado />}
                              title={'Nenhum resultado encontrado'}
                              description="Tente buscar outro termo ou ligue para nossa central de atendimento."
                            />
                          </Col>
                        </LoadingState.NoData>
                        <ListNoBullets id="list-text-list-page">
                          {pageList &&
                            pageList.map((item, index) => (
                              <React.Fragment key={item.title}>
                                <Link
                                  id={`item-text-list-page-${index}`}
                                  to={normalizeUrl(`${props.location.pathname}/${item.slug}`)}
                                >
                                  <TextListItemStyled>
                                    <H3 cell>{item.title}</H3>
                                    <Synonyms array={item.synonyms} />
                                    <Divisor />
                                    <ExamsDetailsLink>
                                      <p>Preparo e mais</p>
                                    </ExamsDetailsLink>
                                  </TextListItemStyled>
                                </Link>
                              </React.Fragment>
                            ))}
                        </ListNoBullets>
                      </LoadingState>
                    </Col>
                  </Row>
                  {showPagination && (
                    <PaginationRow
                      pagination={{ ...paginationProps.pagination, total: itemsCount }}
                      limit={paginationProps.limit}
                    />
                  )}
                </>
              )
            }}
          </PaginationContainer>

          {footer}
        </Col>
      </Row>
    </Grid>
  )
}
