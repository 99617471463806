import React from 'react'

const SvgIcSearch = props => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.5"
      y="0.5"
      width="47"
      height="47"
      rx="23.5"
      fill={props.fill}
      stroke={props.fill}      
    />
    <path
      d="M30.8102 29.1135L27.9258 26.1764C28.8565 24.9656 29.3614 23.4704 29.3595 21.9311C29.3595 20.5603 28.9615 19.2202 28.2158 18.0804C27.47 16.9406 26.4101 16.0522 25.17 15.5276C23.9299 15.003 22.5653 14.8657 21.2488 15.1332C19.9323 15.4006 18.7231 16.0607 17.7739 17.0301C16.8248 17.9994 16.1784 19.2344 15.9165 20.5789C15.6547 21.9234 15.7891 23.317 16.3027 24.5835C16.8164 25.85 17.6863 26.9325 18.8023 27.6941C19.9184 28.4557 21.2306 28.8622 22.5728 28.8622C24.08 28.8641 25.5441 28.3484 26.7297 27.398L29.6056 30.3437C29.6844 30.4249 29.7782 30.4894 29.8816 30.5334C29.985 30.5774 30.0959 30.6 30.2079 30.6C30.3199 30.6 30.4308 30.5774 30.5341 30.5334C30.6375 30.4894 30.7313 30.4249 30.8102 30.3437C30.8897 30.2632 30.9528 30.1674 30.9959 30.0618C31.039 29.9562 31.0611 29.843 31.0611 29.7286C31.0611 29.6142 31.039 29.501 30.9959 29.3954C30.9528 29.2898 30.8897 29.194 30.8102 29.1135ZM17.4828 21.9311C17.4828 20.903 17.7813 19.8979 18.3406 19.0431C18.8999 18.1882 19.6949 17.5219 20.625 17.1285C21.555 16.735 22.5785 16.6321 23.5659 16.8327C24.5532 17.0332 25.4602 17.5283 26.172 18.2553C26.8839 18.9823 27.3687 19.9086 27.5651 20.917C27.7615 21.9253 27.6607 22.9705 27.2754 23.9204C26.8902 24.8703 26.2378 25.6822 25.4007 26.2534C24.5637 26.8246 23.5796 27.1294 22.5728 27.1294C21.2229 27.1294 19.9282 26.5817 18.9736 25.6069C18.0191 24.632 17.4828 23.3098 17.4828 21.9311Z"
      fill="white"
    />
  </svg>


)

export default SvgIcSearch
