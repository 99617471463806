// tslint:disable:no-console
import { Spacing } from 'atomic/legacy/obj.constants'
import styled from 'styled-components'
//import { TextField as MUITextField } from '@material-ui/core'
import { theme } from '../../obj.theme'

export const SearchLoadingStyled = styled.div`  
  position: absolute;
  right: ${Spacing.Small};
  width: 100%;
  top: 27px;
  color: ${props => props.theme.color.primary} !important;
  border-color: 2px solid ${props => props.theme.color.primary} !important;
`

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${theme.typography.primary} !important;
  width: 101.5%;
  border-radius: 45px;
  border: solid 2px ${theme.color.primary};
  background-color: #FFF;
  color: ${theme.color.primary};  
  padding: 8px 6px 8px 20px;
  height: fit-content;
  margin-top: 16px;
  margin-bottom: 32px;  
`

// export const TextField = styled(MUITextField)`  
//   width: 100%;  
//   color: ${theme.color.primary} !important;
//   font-family: ${theme.typography.primary} !important;   
  
//   .MuiOutlinedInput-root {
//     font-family: ${theme.typography.primary} !important;
//     color: ${theme.color.primary} !important;        

//     svg {
//       color: ${theme.color.primary} !important;      
//     }

//     :not(.Mui-focused) {
//       font-family: ${theme.typography.primary} !important;
//       color: ${theme.color.primary} !important;
//     }
//   }
    
//   button {
//     margin-top: 0;
//   }

//   label {    
//     color: ${theme.color.primary} !important;
//   }
  
//   .placeholder {    
//     color: ${theme.color.primary} !important;
//   }

//   .MuiTextField-root {    
//     opacity: 1 !important;
//     color: ${theme.color.primary} !important;    
//   }
  
//   .Mui-focused {
//     color: ${theme.color.primary} !important;
//     font-family: ${theme.typography.primary} !important;    

//     input {
//       color: ${theme.color.primary} !important;      
//     }

//     fieldset {
//       border-color: ${theme.color.primary} !important;      
//     }
//   }
// `

export const TextField = styled.input`  
  width: 100%;  
  color: ${theme.color.primary} !important;
  font-family: ${theme.typography.primary} !important;
  font-size: 16px;
  border: 0 none;
  outline: 0;
  
  ::placeholder {    
    color: ${theme.color.primary} !important;
    opacity: 0.7;
  }  
`