import styled from 'styled-components'
import ColorFunc from 'color'
import { Color } from 'atomic'
import { theme } from '@root/src/components/obj.theme'

export const TextListItemStyled = styled.li`
  padding: 20px 30px 15px;
	border-radius: 16px;
	border: 1px solid ${Color.GrayLight};
	margin: 0 0 16px 0;
	text-decoration: none;
	box-shadow: 4px 5px 5px -3px rgba(0, 0, 0, 0.1);

  :hover {
    background-color: ${ColorFunc(Color.GrayLight)
      .alpha(0.3)
      .hsl()
      .string()};
  }
  :active {
    background-color: ${ColorFunc(Color.GrayLight)
      .alpha(0.2)
      .hsl()
      .string()};
  }
`

export const TopExamesWrapper = styled.div`
	display: flex;
	flex-direction: column;
  border-radius: 16px;
	background-color: ${Color.Neutral};
  margin-bottom: 16px;

	/* TODO: Padronizar media queries, no tema? */
	@media (max-width: 767px) {
		display: none;
	}
`

export const TotalExamesWrapper = styled.div`
	display: flex;
  align-items: center;
	flex-direction: row;
  border-radius: 12px;
  width: 100%;
  height: 48px;
	background-color: ${Color.Neutral};
  padding: 16px;
  margin-left: 8px;
  margin-right: 6px;  
`

export const TopExamesText = styled.p`
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
  padding: 32px 16px 16px;
	letter-spacing: normal;
	text-align: left;
	margin-left: 5px;  
	color: ${Color.Black};
`

export const TopExame = styled.div`
cursor: pointer;
height: 60px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
color: ${Color.GrayXDark};
background-color: #ffff;
border: solid 1px #ffff;
border-radius: 4px;
padding: 0 15px 0 15px;
margin-bottom: 15px;
margin-left: 16px;
margin-right: 16px;

svg {
  width: 6px !important;
}

p {
  width: 85%;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.GrayXDark};
}
`

export const ExamsDetailsLink = styled.div`
  display: flex;  
  align-items: center;
  justify-content: flex-end;

p {
	font-size: 16px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
  padding: 32px 16px 16px;
	letter-spacing: normal;
	text-align: left;
	margin-left: 5px;  
	color: ${theme.color.primary} !important;
}
`

export const FilterExamsWrapper = styled.div`
  font-family: ${props => props.theme.typography.primary} !important;
	display: flex;
  justify-content: center;
	flex-direction: column;
  border-radius: 16px;
	background-color: ${Color.Neutral};
  margin-bottom: 16px;
  padding: 1px 16px 1px;
`

export const CheckboxContainer = styled.div`
  font-family: ${props => props.theme.typography.primary} !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${Color.Neutral};
	padding: 8px 5px;
	border-radius: 3px;
	margin: 5px 0;
	cursor: pointer;
`
export const CheckboxSpan = styled.span`
  font-family: ${props => props.theme.typography.primary} !important;
	color: #77787b;
	font-size: 12px;
  line-height: 2;
`

export const Checkbox = styled.input`
	display: block !important;
  font-family: ${props => props.theme.typography.primary} !important;  
	position: absolute;
	opacity: 0;
	z-index: 2;
	width: 18px !important;
	height: 18px !important;

	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
		//display: flex;
		align-items: center;    
		margin: 0;
		font-size: 14px;
	}

	& + label:before {
		content: '';
    margin-top: -2px;
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 18px !important;
		height: 18px !important;
		background: transparent;
		border: 2px solid #a6a6a6;
		border-radius: 3px;
	}

	&:hover + label:before {
		background: ${theme.color.primary};
	}

	&:focus + label:before {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
	}

	&:checked + label:before {
		background: ${theme.color.primary};
		width: 18px !important;
		height: 18px !important;
	}

	&:disabled + label {
		color: ${Color.Black};
		cursor: auto;
	}

	&:disabled + label:before {
		box-shadow: none;
		background: #ddd;
	}

	&:checked + label:after {
		content: '';
		position: absolute;
		left: 6px;
		background: white;
		width: 2px;
		height: 2px;
		box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
		transform: rotate(45deg);
		top: 8px;
	}
`
export const FilterExamsText = styled.p`
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
  padding: 24px 0px 1px;
	letter-spacing: normal;
	text-align: left;
	margin-left: 5px;  
	color: ${Color.Black};
`