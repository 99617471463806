export interface ExamSearchResponse {
  exams: {
    title: string
    slug: string
    synonyms: string[]
  }[]
  total: {
    value?: number 
    relation?: string
  } 
}

interface ExamElasticSearchResponse {
  data: Data
  status: number
  statusText: string
  headers: Headers
  config: Config
}

export const mapToExamSearchResponse = (input: ExamElasticSearchResponse) => {
  return {
    exams: input.data.hits.hits.map(hit => ({
      title: hit._source.name,
      // title: hit._source.name ? hit._source.name : hit._source.title,
      slug: hit._source.slug,
      score: hit._score,
      synonyms: hit._source.synonyms
    })),
    total: input.data.hits.total
  } as ExamSearchResponse
}

//////////////////////////////////////////////////
// generated using: http://json2ts.com/
interface Shards {
  total: number
  successful: number
  skipped: number
  failed: number
}

interface Topico {
  titulo: string
  conteudo: string
}

interface InstrucoesGerais {
  topicos: Topico[]
}

interface Topico2 {
  titulo: string
  conteudo: string
}

interface ManualExames {
  topicos: Topico2[]
}

interface Metadata {
  idLegado: string
  nome: string
  sigla: string
  sinonimia: string
  instrucoesGerais: InstrucoesGerais
  manualExames: ManualExames
  destaque: string
}

interface Source {
  slug: string
  title: string
  name: string
  synonyms: string[]
  content?: any
  bucket: string
  type_slug: string
  created: Date
  created_at: Date
  modified_at: Date
  status: string
  locale?: any
  published_at: Date
  metadata: Metadata
}

interface Hit {
  _index: string
  _type: string
  _id: string
  _score: number
  _source: Source
}

interface Hits {
  total: number
  max_score: number
  hits: Hit[]
}

interface Data {
  took: number
  timed_out: boolean
  _shards: Shards
  hits: Hits
}

interface Params {
  q: string
  from: number
  size: number
}

interface Headers2 {
  Accept: string
}

interface Config {
  url: string
  method: string
  params: Params
  headers: Headers2
  baseURL: string
  transformRequest: any[]
  transformResponse: any[]
  timeout: number
  xsrfCookieName: string
  xsrfHeaderName: string
  maxContentLength: number
}
