import { TextListItem } from '@app/components/org.text-list-page/text-list-page.component'
import { PageProps } from '@app/utils/local-types'
import { CosmicjsInformacoesEstaticas } from '@root/src/data/graphql/graphql-types'
import { graphql } from 'gatsby'
import React from 'react'
import { ExamListPage } from './component/exam-list-page.component'

export interface ExamManualListTemplateProps {
  items: TextListItem[]
}

const ExamManualListTemplate: React.FunctionComponent<PageProps<
  ExamManualListTemplateProps
>> = props => {
  const staticData = props.data.cosmicjsInformacoesEstaticas as CosmicjsInformacoesEstaticas

  return (
    <ExamListPage
      siteUrl={props.data.site.siteMetadata.siteUrl}
      fleuryElasticSearchUrl={props.data.site.siteMetadata.fleuryElasticSearchUrl}
      location={props.location}
      items={props.pageContext.items}
      staticData={staticData}
    />
  )
}

export default ExamManualListTemplate

export const query = graphql`
  query ManualDeExamesTemplate {
    cosmicjsInformacoesEstaticas(slug: { eq: "manual-de-exames" }) {
      metadata {
        titulo
        buscaPlaceholder
        col2Titulo
        col2Conteudo
        textoExamesMaisBuscados
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        fleuryElasticSearchUrl
      }
    }
  }
`
