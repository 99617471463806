import React from 'react'
import styled, { css } from 'styled-components'

const styleDefault = css`
	color: ${props => props.theme.color.primary};
`

const H2 = styled.h2`
	//${styleDefault}
	font-size: 20px;
	font-family: ${props => props.theme.typography.primary};
	color: #302A38 !important;
	margin: 14px 0 17px;
	margin-block-end: 0.3em;
`

const Underline = styled.div`	
	height: 5px;
	width: 90px;
	margin-top: 2px;
	border-radius: 3px;
	background: ${props => props.theme.color.primary};

	@media (max-width: 768px) {
		margin-left: 15px;
	}
`

const H1 = styled.h3`
	${styleDefault}
	font-family: ${props => props.theme.typography.primary};
	font-size: 24px;
	margin: 9.3px 0;
	color: #302A38;

	@media (min-width: 768px) {
		//font-size: 40px;
		// margin: 30px 15px 40px;
	}
	@media (min-width: 1024px) {
		// margin: 30px 0 0;
	}

	@media (max-width: 768px) {
		padding-left: 15px;
	}
`

const H4 = styled.h4`
	${styleDefault}
	font-family: ${props => props.theme.typography.primary};
	font-size: 20px;
	margin: 8px 0;
	color: #302A38;

	@media (min-width: 768px) {
		//font-size: 40px;
		// margin: 30px 15px 40px;
	}
	@media (min-width: 1024px) {
		// margin: 30px 0 0;
	}

	@media (max-width: 768px) {
		padding-left: 15px;
	}
`

const HighLightPhone = styled.a`
	text-decoration: none;
	color: ${props => props.theme.color.primary};
	font-size: 16px;
	margin: 9.3px 0;
`

const HighLight = styled.strong`
	color: ${props => props.theme.color.primary};
	font-size: 16px;
	margin: 9.3px 0;
`

const SubTitleCard = styled.h6`
	font-size: 18px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #464646;
	margin: 0;
	padding: 10px 0;

	@media (max-width: 768px) {
		display: none;
	}
`

const MainTitle = ({ children, id }:any) => (
	<div>
		<H1 id={id}>{children}</H1>
		<Underline />
	</div>
)

const SubTitle = ({ children, id }: any) => (
	<div>
		<H2 id={id}>{children}</H2>
		<Underline />
	</div>
)

const SubTitleExams = ({ children, id }: any) => (
	<div>
		<H4 id={id}>{children}</H4>
		<Underline />
	</div>
)

export { MainTitle, SubTitle, HighLight, SubTitleCard, SubTitleExams, HighLightPhone }
